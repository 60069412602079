.workbook-iframe {
    width: 100%;
    height: 100%;
}

.workbook-page {
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    border: none;
}