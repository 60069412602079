.EmailSent {
    height: 100vh;
}

.EmailSent-Body {
    height: calc(100% - 104px - 232px);
}

.greetMessage {
    height: 50px;
}

.messageBody {
    width: 500px;
    height: 300px;
    margin: 0 auto;
    background-color: #f2f3f5;
}

.bodyCenter {
    padding: 30px 20px 20px 20px;
}

.successfullMessage {
    font-size: 18px;
}

.return {
    height: 48px;
    width: 96px;
    background-color: #0091dc;
    border-color: #0091dc;
    color: #fff;
    border-radius: 4px;
    box-shadow: none !important;
    font-size: 16px;
    margin-top: 90px;
    margin-left: 350px
}