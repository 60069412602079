.terms-iframe {
    width: 100%;
    height: 100%;
}

.terms-page {
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    border: none;
}