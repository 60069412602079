.loading {
    filter: blur(20px) saturate(60%) opacity(80%);
}

.loaded {
    filter: blur(0px) saturate(100%) opacity(100%);
    transition: filter 0.1s ease-in;
}

.image {
    clip-path: border-box;
    object-fit: cover;
}