.separator {
    background-color: #d8dce1;
    height: 40px;
}

.BMC-MainContent {
    height: calc(100vh - 98px) !important;
    max-width: 95%;
    margin: 8px auto 48px auto;
}

.appName {
    background-color: #f2f3f5;
    font-size: 24px;
    height: 80px;
    padding: 16px;
    margin-bottom: 16px;
}

.bmcPictures {
    height: 100%;
    width: 100%;
}

.imageSeparator {
    width: 2%;
    display: inline-block
}

.bmc-picture {
    width: 32%;
    height: 86%;
    display: inline-block;
    overflow: hidden;
}