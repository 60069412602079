.account-icon {
    height: 16px;
    max-height: 16px;
    min-height: 16px;
    width: 16px;
    max-width: 16px;
    min-width: 16px;
    margin-right: 4px;
}

.account-menu .profile-dropdown-name {
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    color: var(--fwe-black);
    white-space: nowrap;
}

.account-menu .profile-dropdown-email {
    color: var(--fwe-black);
}

.account-menu .account-info {
    padding: 24px 16px;
    min-width: 240px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    border: none;
    outline: none;
    line-break: auto;
    line-height: 1.5;
    text-align: start;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    width: 100%;
}

.account-btn {
    display: flex;
    align-items: center;
    transition: none;
}

.account-menu .dropdown-menu .logoutBtn {
    height: 40px;
    width: 100%;
    font-size: 14px;
    cursor: pointer;
    align-items: center;
    background-color: var(--fwe-gray-200);
    padding: 0 16px;
    flex-grow: 1;
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;
}

.account-menu .dropdown-menu .logoutBtn:hover {
    background-color: var(--fwe-caerul);
}

.account-menu .dropdown-menu .logoutIcon {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
}

.account-menu .dropdown-menu .logoutText {
    vertical-align: middle;
}

.account-menu .avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
}

.account-menu .avatar-placeholder {
    border: 2px solid #b6bec6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-menu .avatar-letter {
    height: min-content;
    font-size: medium;
    color: var(--fwe-black);
    padding-bottom: 2px;
}

.account-menu .avatar-letter-container {
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 50%;
    background-color: #e5e8eb;
}