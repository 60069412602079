.cookie-policy-content {
    padding: 32px;
}

.welcome-text {
    font-weight: bold;
    font-size: 14px;
}

.we-use-cookies-text {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 1.5rem !important;
    margin-top: 1rem !important;
}

.policy-modified-text {
    margin-bottom: 1rem !important;
}

.cookies-description-section {
    margin-right: 25px;
    margin-bottom: 42px;
}

.description-container {
    margin-bottom: 24px;
}

.buttons-container {
    font-size: 12px;
    line-height: 18px;
    color: #aeaeae;
}

.edit-cookies-settings-section {
    margin-top: 42px;
}

.cookie-btn {
    margin: 6px;
    font-weight: 400;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 4px;
    font-size: 16px;
    height: 48px;
    max-height: 48px;
    min-height: 48px;
    min-width: fit-content;
    padding: 0 23px;
    background-color: var(--fwe-btn-hero);
    color: var(--fwe-white);
}

.cookie-btn:hover {
    background-color: var(--fwe-btn-hero-hover);
}

.necessary-cookies-only-btn {
    background-color: var(--fwe-btn);
    color: #333;
}

.necessary-cookies-only-btn:hover {
    background-color: var(--fwe-btn-hover);
}

.cookies-policy-banner {
    align-items: center;
    background-color: var(--fwe-btn);
    bottom: 0;
    color: var(--fwe-black);
    cursor: pointer;
    display: flex;
    padding: 6px 18px;
    position: fixed;
    right: 0;
    height: fit-content;
    width: fit-content;
}

.cookies-policy-banner:hover {
    background-color: var(--fwe-btn-hover);
}

.cookies-policy-banner-icon {
    height: 16px;
    width: 16px;
    margin-right: 4px;
}

.edit-cookies-link {
    background-color: transparent;
    color: var(--fwe-hero);
    text-decoration: none;
}

.edit-cookies-link:hover {
    background-color: transparent;
    color: var(--fwe-hero-dark);
    text-decoration: none;
}