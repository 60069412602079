body {
    margin: 0;
    font-family: var(--fwe-font-family-sans-serif);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: var(--fwe-font-family-monospace);
}

