.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.55);
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s linear;
    z-index: var(--fwe-z-index-modal);
}

.modal-content {
    background-clip: padding-box;
    background: #fff;
    border: 0 solid rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    padding: 48px !important;
}

.modal-box {
    position: relative;
    width: auto;
    margin: 1.75rem auto;
    max-width: 650px !important;
    transition: transform .3s ease-out;
}

.close-btn {
    position: absolute;
    top: 32px;
    right: 32px;
}

.close-icon {
    height: 32px;
    width: 32px;
}