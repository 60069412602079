.cookie-policy {
    color: var(--fwe-text);
    font-size: var(--fwe-font-size-base);
    font-weight: var(--fwe-font-weight-normal);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    padding-left: 12px;
    padding-right: 12px;
    max-width: 1440px;
    width: 90%;
}

.cookie-policy-headline {
    text-align: left;
    color: var(--fwe-text);
    font-family: var(--fwe-font-family-sans-serif);
    box-sizing: border-box;
    font-feature-settings: "lnum";
    -webkit-font-smoothing: antialiased;
    margin: 0.67em 0px;
    margin-bottom: 1.5rem !important;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
}

.numbered-subtitle {
    display: flex !important;
    align-items: flex-start !important;
    margin-bottom: 24px !important;
    font-size: 26px;
    line-height: 31.2px;
    margin-top: 32px;
}

.point-list {
    line-height: 1.5;
    text-align: left;
    color: var(--fwe-text);
    font-family: var(--fwe-font-family-sans-serif);
    font-size: var(--fwe-font-size-base);
    font-weight: var(--fwe-font-weight-normal);
    margin-bottom: 1rem;
    margin-top: 0px;
    margin-left: 1.5rem !important;
}

.title {
    font-weight: var(--fwe-font-weight-bold);
}

.cookie-functions {
    margin-bottom: 1rem;
    margin-top: 0px;
    margin-left: 1.5rem !important;
}

.cookie-functions li {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.cookies-table {
    border-collapse: collapse;
    margin-bottom: 1rem;
    width: 100%;
    margin-left: 3rem !important;
    margin-right: 3rem !important;
    margin-top: 14px !important;
    padding: 32px;
}

.cookies-table td {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
}

.cookies-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 8px;
}

.imprint-and-data-privacy-description {
    margin-left: 38px;
}

.input-radio-wrapper {
    display: flex;
    margin-top: 3px;
}

.radio-label {
    margin-left: 30px;
}

.replaced-span {
    padding: 8px;
}

.about-cookies-link {
    background-color: transparent;
    color: var(--fwe-hero-dark);
    text-decoration: none;
}

.alert-warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
}

.clickable-text:hover {
    cursor: pointer;
    color: var(--fwe-hero-dark);
}