.information {
    border-bottom: 1px solid var(--fwe-sucanul);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    padding-left: 12px;
    padding-right: 12px;
    max-width: 1440px;
    width: 90%;
    min-height: calc(100vh - 386px);
}

.information-headline {
    margin: 0.67em 0px;
    margin-bottom: 2rem !important;
    font-size: 32px;
    font-weight: 700;
    line-height: 48px;
}

.information-item {
    display: flex !important;
    justify-content: space-between !important;
    border-top: 1px solid rgb(229, 232, 235);
}

.item-title {
    font-size: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
}

.show-details {
    text-decoration: none;
    color: var(--fwe-text);
    font-size: 16px;
    width: fit-content;
    margin-top: 32px;
}
