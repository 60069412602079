.BMC-Footer {
    margin-left: auto;
    margin-right: auto;
}

.footerHeader {
    background-color: #f2f3f5;
    height: 88px;
}

.name {
    margin-left: auto;
    margin-right: auto;
}

.informations {
    background-color: #f2f3f5;
    font-size: 24px;
    padding-top: 24px;
}

.generalLinks {
    margin-bottom: 24px;
}

.details {
    color: #a3b2bc;
    text-decoration: none;
    font-size: 16px;
    margin-right: 30px;
}

.terms {
    margin-right: 0;
}

.copyright {
    color: #a3b2bc;
    font-size: 16px;
    padding-bottom: 48px;
}