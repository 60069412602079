.BMC-Header {
    height: 64px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.bmcHeader {
    height: 64px;
}

.buttonsHeader {
    margin-left: auto;
    margin-right: 0;
}

.headerText {
    font-weight: 500;
    font-size: 16px;
}

.logoFesto {
    height: 20px;
}

.btn-default {
    background: transparent;
}

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    display: none;
    font-size: 14px;
    color: #333;
    background-color: var(--fwe-white);
    border: none;
    border-radius: 0;
    padding: 0;
    min-width: fit-content;
    box-shadow: 0 0 8px rgba(0, 0, 0, .29) !important;
}

.dropdown-item {
    width: 100%;
    text-align: center;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.arrow {
    color: transparent;
    height: .5rem;
    margin: 0 .3rem;
    width: 1rem;
    border-bottom-color: white;
    border-width: 0 .5rem .5rem;
    border-style: solid;
    top: 1px;
    margin: 0px;
    position: absolute;
    transform: translate(15px, -8px);
}