.organizatonPage {
    
}

.lxorgpage {
    margin-top: 20px;
    margin-left: 20px;
    width: calc(100% - 48px);
    height: calc(100vh - 84px);
    border: none;
}
