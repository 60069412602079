.countrySelector {
    width: 100%;
    height: 30px;
    background: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom-color: #b6bec6;
    border-bottom-width: 2px;
}
