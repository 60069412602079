.Login-MainContent {
    position: relative;
    height: 92vh;
    padding-top: 30px;
    overflow: hidden;
}

.background-image {
    position: absolute;
    top: 64px;
    left: 0;
    height: calc(100% + 64px);
    width: 100%;
    object-fit: fill;
    z-index: -1;
    overflow: hidden;
}

.loginContent {
    z-index: 1;
    height: fit-content;
    opacity: 1;
    width: 400px;
    background-color: white;
    padding: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border: none;
    transition: height 0.1s ease-out;
}

.loginHeader {
    padding-top: 20px;
    font-size: 32px;
    font-weight: 800;
}

.inputLabel {
    font-size: 12px;
    font-weight: 700;
}

.submitButton {
    width: 100%;
    opacity: 1;
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    transition: opacity 0.3s ease-out;
}

input {
    background: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: thin solid var(--fwe-black);
    border-bottom-color: #b6bec6;
    color: var(--fwe-black);
    height: 30px;
}

input:hover {
    color: var(--fwe-hero);
    border-bottom-color: var(--fwe-hero);
    outline: none;
}

input:focus {
    border-bottom-color: var(--fwe-hero);
    outline: none;
}

.rememberMe {
    margin-bottom: 5px;
    margin-top: 5px;
}

.errorText {
    padding-top: 10px;
    font-size: 14px;
    color: #ff0000;
}

.disabled-button {
    cursor: not-allowed;
    opacity: 0.3;
}

.login-option-link {
    margin-right: 1rem;
    padding: 0;
    color: var(--fwe-hero);
    text-decoration: none;
}