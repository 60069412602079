.App {
    text-align: center;
}

.link {
    color: #000f;
    text-decoration: none;
}

.border-bottom {
    border-bottom: 2px solid #e5e8eb;
}

.border-right {
    border-right: 2px solid #e5e8eb;
}

button {
    background: transparent;
    border: none !important;
    cursor: pointer;
    padding: 0;
    font-family: inherit;
}

.justify-content-center {
    justify-content: center;
}

.centerAlign {
    align-items: center;
}

.horizontalCenter {
    margin-left: auto;
    margin-right: auto;
}

.rightAlign {
    margin-left: auto;
    margin-right: 8px;
}

.flex {
    display: flex;
}