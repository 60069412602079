.Home {
    height: 100vh;
}

.Home-Header {
    height: 64px;
    font-size: 18px;
    padding: 0 3%;
}

.Home-MainContent {
    height: fit-content;
}

.bmcSimApp {
    width: calc(100% - 48px);
    height: calc(100vh - 64px);
    border: none;
}

.homeSidebar {
    height: calc(100vh - 64px);
    width: 48px;
    padding-top: 32px;
    display: flex !important;
    justify-content: space-between !important;
    flex-direction: column;
}

.icon-top {
    width: 32px;
    padding-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
}

.icon-bottom {
    width: 32px;
    padding-top: 32px;
    padding-bottom: 12px;
    margin-left: auto;
    margin-right: auto;
}