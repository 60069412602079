.reset-password-modal {
    height: 260px;
}

.problems-text {
    font-weight: bold;
    font-size: 14px;
}

.reset-password-text {
    font-weight: bold;
    font-size: 32px;
    padding-bottom: 32px;
    padding-top: 4px;
}

.modal-btn {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 24px;
    padding-right: 24px;
    font-weight: 400;
    width: fit-content;
    border-radius: 4px;
    font-size: 16px;
    height: fit-content;
    background-color: var(--fwe-btn-hero);
    color: var(--fwe-white);
}

.modal-btn:hover {
    background-color: var(--fwe-btn-hero-hover);
}

.button-container {
    display: flex;
    width: 100%;
}

.error-text {
    padding-top: 10px;
    font-size: 14px;
    color: #ff0000;
}

.disabled-button {
    cursor: not-allowed;
    opacity: 0.3;
}

.reset-password-output-box {
    height: 90px;
}

.reset-password-error-box {
    height: 60px;
}