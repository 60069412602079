.contactUs-Main {
    width: 70%;
    padding-top: 24px;
    margin-left: auto;
    margin-right: auto;
}

.contactUs-Header {
    font-size: 30px;
    margin-bottom: 20px;
}

.question {
    font-size: 24px;
    color: #b6bec6;
    margin-bottom: 16px;
}

.labels {
    font-size: 18px;
}

input[type="text"] {
    background: transparent;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom-color: #b6bec6;
    height: 30px;
}

.submitButton {
    height: 48px;
    width: 96px;
    background-color: #0091dc;
    border-color: #0091dc;
    color: #fff;
    border-radius: 4px;
    box-shadow: none !important;
    font-size: 16px;
}

.mandatoryInfos {
    font-size: 12px;
    line-height: 18px;
    height: 40px;
}