.input-radio {
    position: relative;
    min-height: 16px;
    padding: 10px;
}

.input-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.radio-span-btn {
    position: absolute;
    top: 20px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: var(--fwe-white);
    border: 1px solid var(--fwe-gray-400);
    border-radius: 50%;
}

.input-radio .radio-span-btn:hover {
    background-color: var(--fwe-gray-200);
}

.input-radio input:checked + .radio-span-btn {
    background-color: var(--fwe-caerul);
    border: none;
}

.input-radio input:disabled + .radio-span-btn {
    background-color: var(--fwe-btn-disabled) !important;
    border: var(--fwe-btn-disabled) !important;
}

.input-radio input:checked + .radio-span-btn:after {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
    height: 6px;
    border: none;
    border-radius: 50%;
    background: var(--fwe-white);
}