.terms-content {
    padding: 32px;
}

.welcome-text {
    font-weight: bold;
    font-size: 14px;
}

.terms-agreement-text {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 1.5rem !important;
    margin-top: 1rem !important;
}

.terms-modified-text {
    margin-bottom: 1rem !important;
}

.terms-description-section {
    margin-right: 25px;
    margin-bottom: 42px;
}

.description-container {
    margin-bottom: 24px;
}

.agree-btn {
    font-weight: 400;
    padding-left: 64px;
    padding-right: 64px;
    width: fit-content;
    border-radius: 4px;
    font-size: 16px;
    height: 48px;
    background-color: var(--fwe-btn-hero);
    color: var(--fwe-white);
}

.agree-btn:hover {
    background-color: var(--fwe-btn-hero-hover);
}

.read-terms-link {
    background-color: transparent;
    color: var(--fwe-hero);
    text-decoration: none;
}

.read-terms-link:hover {
    background-color: transparent;
    color: var(--fwe-hero-dark);
    text-decoration: none;
}

.center-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.info-section {
    font-size: 12px;
    line-height: 18px;
    color: #aeaeae;
}